<template>
  <div id="feedbackDetails">
    <section class="nav-bar">
      <div class="icon go_leftIcon" @click="jumpBack"></div>
      <div class="nav-bar__title">反馈信息</div>
    </section>
    <div class="detailsContent">
      <i class="detailsIcon"></i>
      <div class="contentTitle">反馈内容</div>
      <div class="contentInformation">{{ data.exContent }}</div>
      <div class="contentTime">
        <span class="date">{{ timestampConversion(data.exDate) }}</span>
      </div>
      <div class="imgList" v-if="fileList.length>0">
        <ul>
          <li class="uploadImg" v-for="(imgs, index) in fileList" :key="index">
            <img
              class="showImg"
              :src="imgs"
              alt=""
              @click="bigImg(fileList, index)"
            />
          </li>
        </ul>
      </div>
    </div>
    <div class="divider"></div>
    <div class="platformReply" v-if="data.exHandleContent">
      <i class="platformIcon"></i>
      <div class="replyTitle">
        <div class="title">平台回复</div>
        <div class="time">{{ timestampConversion(data.exHandleTime) }}</div>
      </div>
      <div class="replyInformation">{{ data.exHandleContent }}</div>
      <div class="replyTime">
        <span class="date"></span><span class="time"></span>
      </div>
      <div class="imgList" v-if="fileList1.length>0">
        <ul>
          <li class="uploadImg" v-for="(imgs, index) in fileList1" :key="index">
            <img
              class="showImg"
              :src="imgs"
              alt=""
              @click="bigImg(fileList1, index)"
            />
          </li>
        </ul>
        <!-- <van-uploader v-model="fileList1" multiple :deletable="false" /> -->
      </div>
    </div>
  </div>
</template>

<script>
import { uploader } from "../utils/vant.js";
import { ImagePreview } from "vant";
import { feedbackEngineerExInfo } from "@/api/home";
export default {
  data() {
    return {
      userId: this.$route.query.userId,
      trackId: this.$route.query.exId,
      data: {},
      fileList: [],
      fileList1: [],
    };
  },
  mounted() {
    this.getFeedbackEngineerExInfo();
  },
  methods: {
    getFeedbackEngineerExInfo() {
      let params = {
        userId: this.userId,
        trackId: this.trackId,
      };
      feedbackEngineerExInfo(params).then((res) => {
        if (res.status) {
          this.data = res.resultMap.track;
          if (this.data.exImgs) {
            let arr = this.data.exImgs.split(",");
            arr.map((e) => {
              this.fileList.push(e);
            });
          }
          if (this.data.exHandleImgs) {
            let arr1 = this.data.exHandleImgs.split(",");
            arr1.map((e) => {
              this.fileList1.push(e);
            });
          }
        }
      });
    },
    //图片预览
    bigImg(imgs, index) {
      ImagePreview({
        images: imgs, // 预览图片的那个数组
        showIndex: true,
        startPosition: index, // 指明预览第几张图
      });
    },
    // 时间戳转换
    timestampConversion(data) {
      var timestamp = new Date(data);
      return (
        timestamp.toLocaleDateString().replace(/\//g, "-") +
        " " +
        timestamp.toTimeString().substr(0, 8)
      );
    },
    // 跳转返回
    jumpBack() {
      window.history.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
#feedbackDetails {
  .nav-bar {
    position: relative;
    width: 750px;
    height: 79px;
    background: #ffffff;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.06);

    .go_leftIcon {
      position: absolute;
      width: 17px;
      height: 30px;
      left: 25px;
      top: 25px;
      background: url("../assets/images/pending/go_left.png") no-repeat;
      background-size: 100%;
    }

    .nav-bar__title {
      font-size: 32px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #111111;
      text-align: center;
      line-height: 79px;
    }
  }

  .detailsContent {
    position: relative;
    width: 750px;
    background: #ffffff;
    margin: 10px 0;
    text-align: left;

    .detailsIcon {
      position: absolute;
      top: 28px;
      left: 30px;
      width: 60px;
      height: 60px;
      background: url("../assets/images/pending/feedbackIcon.png") no-repeat;
      background-size: 100%;
    }

    .contentTitle {
      display: block;
      font-size: 26px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 26px;
      padding-top: 27px;
      margin-left: 112px;
    }

    .contentInformation {
      display: block;
      font-size: 24px;
      width: 540px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #808080;
      margin-top: 18px;
      margin-left: 112px;
      line-height: 32px;
    }

    .contentTime {
      position: absolute;
      top: 33px;
      right: 32px;
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #bfbfbf;
      line-height: 24px;

      .time {
        margin-left: 9px;
      }
    }

    .imgList {
      width: 560px;
      margin: 18px auto 0;
      ul {
        display: flex;
        flex-wrap: wrap;
        li {
          width: 110px;
          height: 110px;
          margin: 10px 14px;
          .showImg {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  .divider {
    width: 690px;
    height: 1PX;
    background: #ededf0;
    margin: 39px auto 0;
  }

  .platformReply {
    position: relative;
    width: 750px;
    background: #ffffff;
    margin: 10px 0;
    text-align: left;

    .platformIcon {
      position: absolute;
      top: 28px;
      left: 30px;
      width: 60px;
      height: 60px;
      background: url("../assets/images/pending/platformIcon.png") no-repeat;
      background-size: 100%;
    }

    .replyTitle {
      display: flex;
      font-size: 26px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 26px;
      padding-top: 27px;
      margin-left: 112px;
      .title {
        flex: 1;
      }
      .time {
        margin-right: 40px;
        color: #bfbfbf;
      }
    }

    .replyInformation {
      display: block;
      font-size: 24px;
      width: 540px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #ff9500;
      margin-top: 18px;
      margin-left: 112px;
      line-height: 32px;
      word-break: break-all;
    }

    .replyTime {
      position: absolute;
      top: 33px;
      right: 32px;
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #bfbfbf;
      line-height: 24px;

      .time {
        margin-left: 9px;
      }
    }

    .imgList {
      width: 560px;
      margin: 18px auto 0;

      ul {
        display: flex;
        flex-wrap: wrap;
        li {
          width: 110px;
          height: 110px;
          margin: 10px 14px;
          .showImg {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
/deep/ .van-uploader__upload {
  display: none;
}
</style>